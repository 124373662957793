<template>
    <v-app>
        <div v-if="user.logged">
            <v-app-bar app>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                <v-divider class="ml-1" vertical></v-divider>
                
                
                <v-icon class="d-none d-md-flex ml-4 mr-2">mdi-calendar</v-icon> <span class="d-none d-md-flex mr-4">{{ calendar }}</span>
                <v-divider vertical class="d-none d-md-flex"></v-divider>

                <v-icon class="d-none d-md-flex ml-4 mr-2">mdi-clock-outline</v-icon> <span class="d-none d-md-flex mr-4">{{ clock }}</span>
                <v-divider vertical class="d-none d-md-flex"></v-divider>
                
                <template v-if="user.data && user.data.entity">
                    <v-icon class="ml-4 mr-2">mdi-office-building</v-icon> 
                    {{ (user.selectedEntity) ? (user.selectedEntity.company.name) ? user.selectedEntity.company.name : user.selectedEntity.name : null }}
                    {{ (!user.selectedEntity) ? (user.data.entity.company.name) ? user.data.entity.company.name : user.data.entity.name : null }}
                </template>

                <v-spacer></v-spacer>

                <v-btn icon color="red" @click="logout()"> <v-icon>mdi-logout-variant</v-icon></v-btn>
            </v-app-bar>

            <v-navigation-drawer app fixed v-model="drawer">
                <v-toolbar class="d-flex justify-center">
                    <img src="./assets/img/movice_one_logo.svg" width="190px" height="auto" />
                    <!-- <span style="color: #22A549; font-weight: 900; font-size: 23px;">MOViCE ONE</span> -->
                </v-toolbar>

                <v-img height="200px" src="./assets/img/bg.jpg">
                    <v-container fill-height fluid>
                        <v-row class="text-center" align="center" justify="center">
                            <v-col v-if="!user.data">
                                <v-progress-circular :size="70" :width="7" color="white" indeterminate>
                                </v-progress-circular>
                            </v-col>

                            <v-col v-if="user.data">
                                <v-avatar size="64">
                                    <v-gravatar :email="user.data.email" protocol="https" default-img="mm" />
                                    <!-- <v-img src="./assets/img/image.jpg" /> -->
                                </v-avatar>

                                <div class="mt-3 grey--text text--lighten-3 subtitle-1 font-weight-bold">
                                    {{ user.data.name }}
                                </div>

                                <div class="mt-0 grey--text text--lighten-3 caption">
                                    {{ $store.state.api.user.data.role.name }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-img>

                <v-list nav dense v-if="user.data">
                    <v-list-item-group>
                        <v-subheader>{{ $t("sub_main") }}</v-subheader>
                        <v-divider></v-divider>

                        <v-list-item :to="{ name: 'dashboard' }" active-class="primary white--text">
                            <v-list-item-icon>
                                <v-icon>mdi-poll</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.dashboard") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{ name: 'patients' }" active-class="primary white--text" v-if="user.data.hasPerm('patient')">
                            <v-list-item-icon>
                                <v-icon>mdi-medical-bag</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.patients") }} </v-list-item-title>

                            <v-spacer></v-spacer>

                            <v-avatar
                                v-if="stats.tests"
                                color="light-blue darken-2"
                                size="22"
                            >
                                <span class="white--text text-caption">{{stats.tests}}</span>
                            </v-avatar>
                        </v-list-item>

                        <v-list-item :to="{ name: 'devices' }" active-class="primary white--text" v-if="user.data.hasPerm('device')">
                            <v-list-item-icon>
                                <v-icon>mdi-tablet-cellphone</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.devices") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{ name: 'calendar' }" active-class="primary white--text">
                            <v-list-item-icon>
                                <v-icon>mdi-calendar</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.calendar") }}</v-list-item-title>

                            <v-spacer></v-spacer>

                            <v-avatar
                                v-if="stats.today_events"
                                color="teal darken-1"
                                size="22"
                            >
                                <span class="white--text text-caption">{{stats.today_events}}</span>
                            </v-avatar>
                        </v-list-item>

                        <v-list-item :to="{ name: 'calls' }" active-class="primary white--text" v-if="user.data.hasPerm('call')">
                            <v-list-item-icon>
                                <v-icon>mdi-video-wireless-outline</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.calls") }}</v-list-item-title>

                            <v-spacer></v-spacer>

                            <v-avatar
                                v-if="stats.calls"
                                color="blue-grey darken-1"
                                size="22"
                            >
                                <span class="white--text text-caption">{{stats.calls}}</span>
                            </v-avatar>
                        </v-list-item>

                        <v-list-item :to="{ name: 'videos' }" active-class="primary white--text" v-if="user.data.hasPerm('video')">
                            <v-list-item-icon>
                                <v-icon>mdi-filmstrip-box-multiple</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.videos") }}</v-list-item-title>
                        </v-list-item>

                        
                        <v-list-item :to="{ name: 'helprequests' }" active-class="primary white--text" v-if="user.data.hasPerm('helprequest')">
                            <v-list-item-icon>
                                <v-icon>mdi-lifebuoy</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.helprequests") }}</v-list-item-title>

                            <v-spacer></v-spacer>

                            <v-avatar
                                v-if="stats.helprequests"
                                color="orange darken-3"
                                size="22"
                            >
                                <span class="white--text text-caption">{{stats.helprequests}}</span>
                            </v-avatar>
                        </v-list-item>

                        <template v-if="user.data.hasPerm('entity') || user.data.hasPerm('role') || user.data.hasPerm('user')">
                            <v-subheader>{{ $t("sub_admin") }}</v-subheader>
                            <v-divider></v-divider>
                        </template>

                        <v-list-item
                            :to="{ name: 'entities' }"
                            active-class="primary white--text"
                            v-if="user.data.hasPerm('entity')"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-office-building</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.entities") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            :to="{ name: 'roles' }"
                            active-class="primary white--text"
                            v-if="user.data.hasPerm('role')"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-group</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.roles") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            :to="{ name: 'users' }"
                            active-class="primary white--text"
                            v-if="user.data.hasPerm('user')"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-multiple-check-outline</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.users") }}</v-list-item-title>
                        </v-list-item>

                        <v-subheader>{{ $t("sub_account") }}</v-subheader>
                        <v-divider></v-divider>

                        <v-list-item :to="{ name: 'settings' }" active-class="primary white--text">
                            <v-list-item-icon>
                                <v-icon>mdi-cog</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.settings") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="logout()" active-class="light-green darken-2 white--text">
                            <v-list-item-icon>
                                <v-icon>mdi-logout-variant</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ $t("routes.logout") }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        </div>

        <v-main>
            <transition name="fade" v-if="user.data || !user.logged">
                <router-view ref="rt" />
            </transition>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    async mounted() {
        if (this.user.logged) await this.$store.dispatch("api/user/current");

        if (!this.user.logged) this.logout();

        this.loadStats();

        this.today = new Date();
        this.interval = setInterval(() => {
            this.today = new Date();

            if(this.user.logged){
                this.loginTime++;
                if(this.loginTime > 900) this.logout();
                
                this.loadStatsCounter++;

                if(this.loadStatsCounter > 10){
                    this.loadStatsCounter = 0;
                    this.loadStats();
                }

                this.isLogin();
            }
        }, 1000);

        window.onload = this.resetTime;
        window.onclick = this.resetTime;
        window.onkeypress = this.resetTime;
        window.ontouchstart = this.resetTime;
        window.onmousemove = this.resetTime;
        window.onmousedown = this.resetTime;
        window.addEventListener('scroll', this.resetTime, true);

        this.$store.dispatch("api/user/setEntityChangeListener", function(){
            this.loadStats();
        }.bind(this));
    },

    beforeDestroy(){
        clearInterval(this.interval);
    },

    data: () => ({
        loginTime: 0,
        loadStatsCounter: 0,
        drawer: true,
        interval: null,
        today: null,

        form:{},

        stats:{
            tests: null,
            helprequests: null,
            calls: null,
            today_events: null,
        }
    }),

    computed: {
        user() {
            return this.$store.state.api.user;
        },

        calendar(){
            if(!this.today) return;
            return this.today.toLocaleDateString([], { day: "2-digit", month: "short", weekday: "long" });
        },
        
        clock(){
            if(!this.today) return;
            return String(this.today.getHours()).padStart(2, "0")+":"+String(this.today.getMinutes()).padStart(2, "0")+":"+String(this.today.getSeconds()).padStart(2, "0");
        }
    },

    methods: {
        async isLogin(){
            let cond = await this.$store.dispatch("api/user/checkIsLogin");
            if(!cond) this.logout();
        },

        logout() {
            this.$store.dispatch("api/user/logout");
            this.$router.push({ name: "login" });
        },

        resetTime(){
            this.loginTime = 0;
        },

        async loadStats(){
            if(!this.user.logged) return;
            this.selectedEntity();
            const res = await this.$store.dispatch("api/dashboard/stats", this.form);

            if (res.status == 200) {
                if(res.data.tests) {
                    this.stats.tests = res.data.tests;
                    if(this.stats.tests > 9) this.stats.tests = 9+"+";
                }else this.stats.tests = null;

                if(res.data.helprequests) {
                    this.stats.helprequests = res.data.helprequests;
                    if(this.stats.helprequests > 9) this.stats.helprequests = 9+"+";
                }else this.stats.helprequests = null;

                if(res.data.calls) {
                    this.stats.calls = res.data.calls;
                    if(this.stats.calls > 9) this.stats.calls = 9+"+";
                }else this.stats.calls = null;

                if(res.data.today_events) {
                    this.stats.today_events = res.data.today_events.length;
                    if(this.stats.today_events > 9) this.stats.today_events = 9+"+";
                }else this.stats.today_events = null;
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        },
    }
};
</script>
