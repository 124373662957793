export default req => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async stats(ctx, params) {
                const res = await req(ctx, "get", "/dashboard/", params);
                return res;
            },
        }
    };
};
