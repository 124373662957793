export default req => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async create(ctx, params) {
                const res = await req(ctx, "post", "/patient/", params);
                return res;
            },

            async get(ctx, params) {
                const res = await req(ctx, "get", "/patient/", params);
                return res;
            },

            async all(ctx, params) {
                const res = await req(ctx, "get", "/patient/all", params);
                return res;
            },

            async update(ctx, params) {
                const res = await req(ctx, "put", "/patient/", params);
                return res;
            },

            async delete(ctx, params) {
                const res = await req(ctx, "delete", "/patient/", params);
                return res;
            },

            async anonymise(ctx, params) {
                const res = await req(ctx, "delete", "/patient/anonymise", params);
                return res;
            },

            async test_all(ctx, params) {
                const res = await req(ctx, "get", "/patient/test/all", params);
                return res;
            },

            async test_get(ctx, params) {
                const res = await req(ctx, "get", "/patient/test", params);
                return res;
            },

            async test_update(ctx, params) {
                const res = await req(ctx, "put", "/patient/test", params);
                return res;
            },

            async generate_test_csv(ctx, params) {
                const res = await req(ctx, "post", "/patient/generate_test_csv", params);
                return res;
            },

            async generate_report(ctx, params) {
                const res = await req(ctx, "post", "/patient/generate_report", params);
                return res;
            },

            async generate_report_data(ctx, params) {
                const res = await req(ctx, "get", "/patient/generate_report_data", params);
                return res;
            },

            async delete_report(ctx, params) {
                const res = await req(ctx, "delete", "/patient/delete_report", params);
                return res;
            }
        }
    };
};
