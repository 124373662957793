import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store.js";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        meta: { auth: false },
        component: () => import("./views/Login.vue")
    },

    {
        path: "/dashboard",
        name: "dashboard",
        meta: { auth: true },
        component: () => import("./views/Dashboard.vue")
    },
    {
        path: "/patients",
        name: "patients",
        meta: { auth: true },
        component: () => import("./views/Patients.vue")
    },
    {
        path: "/patient/:id/:routefrom?",
        name: "patient",
        meta: { auth: true },
        component: () => import("./views/Patient.vue")
    },
    {
        path: "/devices",
        name: "devices",
        meta: { auth: true },
        component: () => import("./views/Devices.vue")
    },
    {
        path: "/device/:id/:routefrom?",
        name: "device",
        meta: { auth: true },
        component: () => import("./views/Device.vue")
    },
    {
        path: "/videos",
        name: "videos",
        meta: { auth: true },
        component: () => import("./views/Videos.vue")
    },
    {
        path: "/video/:id",
        name: "video",
        meta: { auth: true },
        component: () => import("./views/Video.vue")
    },
    {
        path: "/calendar",
        name: "calendar",
        meta: { auth: true },
        component: () => import("./views/Calendar.vue")
    },
    {
        path: "/calls",
        name: "calls",
        meta: { auth: true },
        component: () => import("./views/Calls.vue")
    },
    {
        path: "/helprequests",
        name: "helprequests",
        meta: { auth: true },
        component: () => import("./views/HelpRequests.vue")
    },
    {
        path: "/helprequest/:id/:routefrom?",
        name: "helprequest",
        meta: { auth: true },
        component: () => import("./views/HelpRequest.vue")
    },
    {
        path: "/call/:id/:routefrom?",
        name: "call",
        meta: { auth: true },
        component: () => import("./views/Call.vue")
    },
    {
        path: "/entities",
        name: "entities",
        meta: { auth: true },
        component: () => import("./views/Entities.vue")
    },
    {
        path: "/entity/:id/:routefrom?",
        name: "entity",
        meta: { auth: true },
        component: () => import("./views/Entity.vue")
    },
    {
        path: "/roles",
        name: "roles",
        meta: { auth: true },
        component: () => import("./views/Roles.vue")
    },
    {
        path: "/users",
        name: "users",
        meta: { auth: true },
        component: () => import("./views/Users.vue")
    },
    {
        path: "/user/:id/:routefrom?",
        name: "user",
        meta: { auth: true },
        component: () => import("./views/User.vue")
    },
    {
        path: "/role/:id/:routefrom?",
        name: "role",
        meta: { auth: true },
        component: () => import("./views/Role.vue")
    },
    {
        path: "/settings",
        name: "settings",
        meta: { auth: true },
        component: () => import("./views/Settings.vue")
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to && to.matched) {
        for (var record of to.matched) {
            if (record.meta.auth && !store.state.api.user.logged) return next({ name: "login" });
            if (record.meta.auth === false && store.state.api.user.logged) return next({ name: "dashboard" });
            else return next();
        }
    }

    return next();
});

export default router;
