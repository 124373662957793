import Vue from "vue";
import axios from "axios";
import { object } from "joi";
import config from "../config";
import { Storage } from "@capacitor/storage";

import user from "./user";
import role from "./role";
import entity from "./entity";
import patient from "./patient";
import device from "./device";
import video from "./video";
import calendar from "./calendar";
import call from "./call";
import dashboard from "./dashboard";
import helprequest from "./helprequest";

var http = init();
var tasks = [];
var cookies = Vue.prototype.$cookies;

const api = {
    namespaced: true,

    modules: {
        user: user(req, http, init),
        role: role(req, http),
        entity: entity(req, http),
        patient: patient(req, http),
        device: device(req, http),
        video: video(req, http),
        calendar: calendar(req, http),
        call: call(req, http),
        dashboard: dashboard(req, http),
        helprequest: helprequest(req, http),
    }
};

function init(){
    const h = axios.create();
    h.defaults.baseURL = config.api.url;
    h.defaults.timeout = 20000;

    return h;
}

function req(ctx, http_func, path, params, timeout = 1) {
    let params_form = params;
    if (http_func == "get" || http_func == "delete") params_form = { params };

    let task = tasks.find((e) => e.path == path);

    if(task){
        task.count++;
    }else{
        task = {
            path: path,
            count: 1
        };

        tasks.push(task);
    }
    
    return new Promise((resolve, reject) => {
        setTimeout(function(){
            http[http_func](path, params_form)
                .then(res => {
                    task.count--;

                    if (res.data.pages)
                        resolve({
                            status: 200,
                            data: res.data.data,
                            limit: res.data.limit,
                            pages: res.data.pages,
                            page: res.data.page,
                            total: res.data.total,
                            task: task
                        });
                    else
                        resolve({
                            status: 200,
                            data: res.data.data,
                            task: task
                        });
                })
                .catch(error => resolve(error_handler([http_func, path, params, task], ctx, error.response)));
        }, timeout);
    });
}

function error_handler(args, ctx, response) {
    const [http_func, path, params, task] = args;

    if (response) {
        console.log(response.status);
        console.log(response.data.error);

        if(response.status == 401){
            cookies.remove("token");
            cookies.remove("selectedEntity");
            Storage.remove({ key: "token" });
            Storage.remove({ key: "selectedEntity" });
        }

        return { status: response.status, data: response.data, task };
    }

    return {
        status: 408,
        data: {
            error:{
                code: "RequestTimeout"
            }
        },
        task
    }

    //return req(ctx, http_func, path, params, 5000);
}

export default api;
