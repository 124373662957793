export default (req, http) => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async create(ctx, params) {
                const res = await req(ctx, "post", "/video/", params);
                return res;
            },

            async get(ctx, params) {
                const res = await req(ctx, "get", "/video/", params);
                return res;
            },

            async all(ctx, params) {
                const res = await req(ctx, "get", "/video/all", params);
                return res;
            },

            async update(ctx, params) {
                const res = await req(ctx, "put", "/video/", params);
                return res;
            },

            async delete(ctx, params) {
                const res = await req(ctx, "delete", "/video/", params);
                return res;
            },

            async upload(ctx, params) {
                http.defaults.timeout = 0;
                const res = await req(ctx, "post", "/video/upload", params);
                http.defaults.timeout = 20000;
                return res;
            },
        }
    };
};
