import Vue from "vue";
import gravatar from "vue-gravatar";

import config from "./config";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./assets/main.scss";

async function init() {
    Vue.config.productionTip = false;
    Vue.prototype.$config = config;

    Vue.component("v-gravatar", gravatar);

    await store.dispatch("api/user/instance");

    new Vue({
        store,
        router,
        vuetify,
        i18n,

        render: function(h) {
            return h(App);
        }
    }).$mount("#app");
}

init();
