export default req => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async all(ctx, params) {
                const res = await req(ctx, "get", "/call/all", params);
                return res;
            },

            async create(ctx, params) {
                const res = await req(ctx, "post", "/call/create", params);
                return res;
            },

            async get(ctx, params) {
                const res = await req(ctx, "get", "/call/", params);
                return res;
            },

            async users(ctx, params) {
                const res = await req(ctx, "get", "/call/users", params);
                return res;
            },

            async update(ctx, params) {
                const res = await req(ctx, "put", "/call/", params);
                return res;
            },

            async delete(ctx, params) {
                const res = await req(ctx, "delete", "/call/", params);
                return res;
            },

            async members(ctx, params) {
                const res = await req(ctx, "put", "/call/members", params);
                return res;
            },

            async removeMember(ctx, params) {
                const res = await req(ctx, "put", "/call/removeMember", params);
                return res;
            },

            async join(ctx, params) {
                const res = await req(ctx, "get", "/call/join", params);
                return res;
            },

            async addPatient(ctx, params) {
                const res = await req(ctx, "put", "/call/addPatient", params);
                return res;
            },
            
            async removePatient(ctx, params) {
                const res = await req(ctx, "put", "/call/removePatient", params);
                return res;
            },
        }
    };
};
