export default {
    searchTimeDelay: 600,

    api: {
        url: "/api/v1",
        reports: "/public/reports/",
        tests: "/public/tests/",
        media: "/media/"
    }
};
