import Vue from "vue";
import VueCookies from "vue-cookies";
import { Storage } from "@capacitor/storage";

Vue.use(VueCookies);
var cookies = Vue.prototype.$cookies;

export default (req, http, init) => {
    return {
        namespaced: true,

        state: {
            logged: false,
            data: null,
            selectedEntity: null,
            entityChangeListener: null
        },

        mutations: {
            SET_LOGGED(state, logged) {
                state.logged = logged;
            },

            SET_DATA(state, data) {
                state.data = data;
            },

            SET_SELECTEDENTITY(state, data) {
                state.selectedEntity = data;
            },

            SET_ENTITYCHANGELISTENER(state, data) {
                state.entityChangeListener = data;
            }
        },

        actions: {
            async instance(ctx, data) {
                var token = null;

                if (data && data.user) {
                    http.defaults.headers.common = {
                        Authorization: "Bearer " + data.token
                    };

                    cookies.set("token", data.token);
                    await Storage.set({ key: "token", value: data.token });

                    ctx.commit("SET_LOGGED", true);
                } else {
                    token = await Storage.get({ key: "token" });

                    if (token.value) {
                        http.defaults.headers.common = {
                            Authorization: "Bearer " + token.value
                        };

                        let selectedEntity = await Storage.get({ key: "selectedEntity" });
                        if(selectedEntity) {
                            selectedEntity = JSON.parse(selectedEntity.value);
                            ctx.commit("SET_SELECTEDENTITY", selectedEntity);
                        }

                        ctx.commit("SET_LOGGED", true);
                    }
                }
            },

            async checkIsLogin(ctx){
                let token = await Storage.get({ key: "token" });

                if (token.value) return true;
                return false;
            },

            logout(ctx) {
                ctx.commit("SET_LOGGED", false);
                ctx.commit("SET_SELECTEDENTITY", null);

                cookies.remove("token");
                cookies.remove("selectedEntity");
                Storage.remove({ key: "token" });
                Storage.remove({ key: "selectedEntity" });

                init();
            },

            async current(ctx, params) {
                var res = await req(ctx, "get", "/user/current", {});

                if(res.status == 200) {
                    var user = res.data;

                    user.hasPerm = perm => {
                        if (!perm || user.role.perms.includes("all")) return true;
                        if (user.role.perms.includes(perm)) return true;
                        return false;
                    };

                    ctx.commit("SET_DATA", user);
                }

                return res;
            },

            async selectEntity(ctx, data){
                if(data._id == ctx.state.data.entity._id){
                    Storage.remove({ key: "selectedEntity" });
                    ctx.commit("SET_SELECTEDENTITY", null);
                }else{
                    await Storage.set({ key: "selectedEntity", value: JSON.stringify(data) });
                    ctx.commit("SET_SELECTEDENTITY", data);
                }

                if(ctx.state.entityChangeListener) ctx.state.entityChangeListener();
            },

            setEntityChangeListener(ctx, callback){
                ctx.commit("SET_ENTITYCHANGELISTENER", callback);
            },

            async get(ctx, params) {
                const res = await req(ctx, "get", "/user/", params);
                return res;
            },

            async auth(ctx, params) {
                const res = await req(ctx, "post", "/user/auth", params);
                if (res.status == 200)
                    await ctx.dispatch("api/user/instance", res.data, {
                        root: true
                    });
                return res;
            },

            async all(ctx, params) {
                const res = await req(ctx, "get", "/user/all", params);
                return res;
            },

            async update(ctx, params) {
                const res = await req(ctx, "put", "/user/", params);
                return res;
            },

            async update_password(ctx, params) {
                const res = await req(ctx, "put", "/user/password", params);
                return res;
            },

            async create(ctx, params) {
                const res = await req(ctx, "post", "/user/", params);
                return res;
            },

            async delete(ctx, params) {
                const res = await req(ctx, "delete", "/user/", params);
                return res;
            }
        }
    };
};
