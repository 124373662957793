export default req => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async get(ctx, params) {
                const res = await req(ctx, "get", "/calendar/", params);
                return res;
            },

            async workHours(ctx, params) {
                const res = await req(ctx, "put", "/calendar/workHours", params);
                return res;
            },

            async event(ctx, params) {
                const res = await req(ctx, "put", "/calendar/event", params);
                return res;
            },

            async addEvent(ctx, params) {
                const res = await req(ctx, "post", "/calendar/addEvent", params);
                return res;
            },

            async remove(ctx, params) {
                const res = await req(ctx, "put", "/calendar/removeEvent", params);
                return res;
            },
        }
    };
};
