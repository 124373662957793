export default req => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async create(ctx, params) {
                const res = await req(ctx, "post", "/role/create", params);
                return res;
            },

            async get(ctx, params) {
                const res = await req(ctx, "get", "/role/", params);
                return res;
            },

            async all(ctx, params) {
                const res = await req(ctx, "get", "/role/all", params);
                return res;
            },

            async entityRoles(ctx, params) {
                const res = await req(ctx, "get", "/role/entityRoles", params);
                return res;
            },

            async permissions(ctx, params) {
                const res = await req(ctx, "get", "/role/permissions", params);
                return res;
            },

            async update(ctx, params) {
                const res = await req(ctx, "put", "/role/", params);
                return res;
            },

            async delete(ctx, params) {
                const res = await req(ctx, "delete", "/role/", params);
                return res;
            }
        }
    };
};
