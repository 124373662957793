export default req => {
    return {
        namespaced: true,

        state: {},

        mutations: {},

        actions: {
            async create(ctx, params) {
                const res = await req(ctx, "post", "/entity/", params);
                return res;
            },

            async get(ctx, params) {
                const res = await req(ctx, "get", "/entity/", params);
                return res;
            },

            async all(ctx, params) {
                const res = await req(ctx, "get", "/entity/all", params);
                return res;
            },

            async update(ctx, params) {
                const res = await req(ctx, "put", "/entity/", params);
                return res;
            },

            async delete(ctx, params) {
                const res = await req(ctx, "delete", "/entity/", params);
                return res;
            }
        }
    };
};
