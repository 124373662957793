import Vue from "vue";
import Vuex from "vuex";
import api from "./api";
import config from "./config";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        config: config
    },
    mutations: {},
    actions: {
        setViewTitle(ctx, title) {
            ctx.commit("SET_VIEWTITLE", title);
        }
    },
    modules: {
        api
    }
});
